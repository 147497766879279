@use "shop-colors";
@use "fonts";

.form__group {
  margin-bottom: 16px;
  position: relative;

  .form__label {
    font-family: var(--font-stack-Open-Sans);
    font-size: 15px;
    font-weight: 600;
    line-height: 1.33px;
    position: absolute;
    top: 26px;
    left: 12px;
    color: var(--gray-stone);
    display: block;
    transition: all 0.2s;
  }

  .form__input {
    background-color: var(--white);
    border-radius: 6px;
    border: 1px solid var(--gray-midnight);
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 20px 16px 4px 12px;
    font-family: var(--font-stack-Open-Sans);
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    color: var(--gray-midnight);
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      box-shadow:
        0 3px 5px -1px #00000033,
        0px 1px 18px 0px #0000001f,
        0px 6px 10px 0px #00000024;
    }

    &:hover {
      background-color: var(--gray-sand);
    }

    &:focus + .form__label,
    &.filled__date + .form__label,
    &:not([value=""]):not([type="date"]) + .form__label,
    &:-webkit-autofill + .form__label {
      font-weight: 700;
      font-size: 11px;
      top: 14px;
      color: var(--gray-stone);
    }

    &[type="date"] {
      appearance: none; /* fixes width on ios safari */

      &:not(:focus):not(.filled__date):not(:-webkit-autofill) {
        color: transparent;
      }

      &::-webkit-date-and-time-value {
        text-align: left;
      }
    }

    /*
    * Floating label fix for chrome with input autofill
    */
    &:-webkit-autofill {
      padding: 20px 40px 0 11px;

      & + .form-label {
        font-size: 14px;
        top: 5px;
        text-align: left;
      }
    }

    &:focus {
      outline: none;
    }

    &:active,
    &:focus {
      color: var(--gray-midnight);
    }

    &--invalid {
      border: 1px solid var(--penny-red);
      color: var(--penny-red);
      &:focus,
      &:active {
        color: var(--penny-red) !important;
      }
      & + .form__label {
        color: var(--penny-red);
      }
    }

    &--disabled {
      color: var(--gray-stone);
      opacity: 50%;
      border: solid 1px var(--gray-midnight);

      & + .form__label {
        opacity: 50%;
        color: var(--gray-stone);
      }
      &:hover {
        background-color: var(--white);
      }
    }
  }

  .form__error:not([hidden]) {
    font-family: var(--font-stack-Open-Sans);
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    margin: 4px 8px 0;
    display: inline-block;
    width: 100%;
    color: var(--penny-red);
  }

  .form__input[value|=""]:focus ~ .form__password-toggle,
  .form__input[value|=""] ~ .form__password-toggle {
    display: none;
  }

  .form__password-toggle {
    background: none;
    border: 0;
    position: absolute;
    right: 12px;
    top: 13px;
    font-family: var(--font-stack-Open-Sans);
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--gray-stone);

    &:hover {
      color: var(--penny-red);
    }
  }
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

// hide Edge password reveal button, https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}
