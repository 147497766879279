.icon {
  &::before {
    content: "";
    width: 24px;
    height: 24px;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &.icon-download {
    &::before {
      mask-image: url("../img/icon-download.svg");
    }
  }

  &.icon-edit {
    &::before {
      mask-image: url("../img/icon-edit.svg");
    }
  }

  &.icon-save {
    &::before {
      mask-image: url("../img/icon-save.svg");
    }
  }

  &.icon-back {
    &::before {
      mask-image: url("../img/icon-chevron.svg");
      transform: rotate(180deg);
    }
  }

  &.icon-delete {
    &::before {
      mask-image: url("../../../common/resources/img/icon-trash.svg");
    }
  }

  &.icon-cancel {
    &::before {
      mask-image: url("../img/icon-x.svg");
    }
  }

  &.icon-cog {
    &::before {
      mask-image: url("../img/icon-cog.svg");
    }
  }

  &.icon-exit {
    &::before {
      mask-image: url("../img/icon-exit.svg");
    }
  }

  &.icon-lock {
    &::before {
      mask-image: url("../img/icon-lock.svg");
    }
  }

  &.icon-mobile-and-folder {
    &::before {
      mask-image: url("../img/icon-mobile-and-folder.svg");
    }
  }

  &.icon-folder {
    &::before {
      mask-image: url("../img/icon-folder.svg");
    }
  }

  &.icon-plane {
    &::before {
      mask-image: url("../img/icon-plane.svg");
    }
  }

  &.icon-arrow-left {
    &::before {
      mask-image: url("../img/icon-arrow-left.svg");
    }
  }
}
