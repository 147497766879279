.textlink {
  font-family: var(--font-stack-Open-Sans);
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  margin: 12px 0 0;

  &-primary {
    color: var(--penny-red);
    &:hover {
      color: var(--gray-stone);
    }
  }

  &-secondary {
    color: var(--gray-stone);
    &:hover {
      color: var(--penny-red);
    }
  }

  &-terms-of-use {
    color: var(--gray-slate);
    &:hover,
    &:visited {
      color: var(--gray-slate);
    }
  }
}
