.infoPage {
  & .card__title {
    margin-top: 24px;
    text-align: left;
    color: var(--gray-midnight);
    font-family: var(--font-stack-Akko);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
  }

  & .info__text {
    color: var(--gray-dark);
    font-family: var(--font-stack-Open-Sans);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 140% */
  }

  & .card__footer {
    margin-top: 24px;
  }
}
