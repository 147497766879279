@use "sass:list";
// @section     Breakpoint Definitions
// @usage       @include breakpoint(980) {
//                  width: 100px;
//              }
// output: (min-width: 980px)

// @usage       @include breakpoint(480, 1024) {
//                  width: 100px;
//              }
// output: (min-width: 480px) and (max-width: 1023px)

// @usage       @include breakpoint(null, 1024) {
//                  width: 100px;
//              }
// output: (max-width: 1023px)

$breakpoints: (320, 480, 600, 768, 980, 1024, 1280, 1480, 2960);

@mixin breakpoint($minimumBreakpoint, $maximumBreakpoint: null) {
  @if $minimumBreakpoint and is-invalid-breakpoint($minimumBreakpoint) {
    @error 'Invalid breakpoint value #{$minimumBreakpoint}. These breakpoints are available: #{$breakpoints} or null.';
  }

  @if $maximumBreakpoint and is-invalid-breakpoint($maximumBreakpoint) {
    @error 'Invalid breakpoint value #{$maximumBreakpoint}. These breakpoints are available: #{$breakpoints} or null.';
  }

  @if is-minimum-greater-than-maximum($minimumBreakpoint, $maximumBreakpoint) {
    @error 'Invalid order of breakpoints. The minimum breakpoint (#{$minimumBreakpoint}) is higher than the maximum breakpoint (#{$maximumBreakpoint})!';
  }

  @if not $minimumBreakpoint and not $maximumBreakpoint {
    @error "Invalid breakpoint values. Both parameters can't be optional.";
  }

  @if get-breakpoint($minimumBreakpoint) and not $maximumBreakpoint {
    @media #{"(min-width: #{$minimumBreakpoint}px)"} {
      @content;
    }
  } @else if not $minimumBreakpoint and get-breakpoint($maximumBreakpoint) {
    @media #{"(max-width: #{$maximumBreakpoint - 1}px)"} {
      @content;
    }
  } @else {
    $query: "(min-width: #{$minimumBreakpoint}px) and (max-width: #{$maximumBreakpoint - 1}px)";
    @media #{$query} {
      @content;
    }
  }
}

@function get-breakpoint($breakpoint) {
  @return list.index($breakpoints, $breakpoint);
}

@function is-invalid-breakpoint($value) {
  @return not list.index($breakpoints, $value);
}

@function is-minimum-greater-than-maximum($min, $max) {
  @if $min == null or $max == null {
    @return false;
  }
  @return $min > $max;
}
