/*
Usage:
<nav class="tab-bar">
  <ul>
    <li class="active">Registrieren</li>
    <li>Anmelden</li>
  </ul>
</nav>
*/

.tab-bar {
  max-width: 480px;
  border-radius: 10px;
  background-color: var(--gray-wild-sand);
  height: 36px;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: var(--font-stack-Akko);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.76px; /* 158.4% */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  & ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 2px;
    height: 100%;
  }

  & li {
    list-style: none;
    flex-grow: 1;
    display: flex;
    height: 32px;

    & a {
      height: 100%;
      padding: 0 16px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--penny-red);
      flex-grow: 1;
      justify-content: center;
      box-sizing: border-box;

      &:focus-visible {
        outline: 1px solid var(--gray-stone);
        outline-offset: 0;
      }

      &:hover {
        box-shadow:
          0 5px 5px 0 rgba(64, 75, 91, 0.14),
          0 2px 1px 0 rgba(64, 75, 91, 0.14),
          0 1px 1px 0 rgba(64, 75, 91, 0.14);
      }
    }

    &.active a {
      background-color: var(--penny-red);
      box-shadow:
        0 1px 5px -3px rgba(205, 20, 20, 0.2),
        0 3px 1px 2px rgba(205, 20, 20, 0.12),
        0 2px 2px 1px rgba(205, 20, 20, 0.14);
      color: var(--white);

      &:hover {
        box-shadow:
          0 2px 1px 0 #cd141433,
          0 1px 1px 0 #4106064d;
      }
    }
  }
}
