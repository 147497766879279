@use "breakpoints";

.error {
  &__image {
    text-align: center;
    margin: 16px 0;
    @include breakpoints.breakpoint(768) {
      margin: 24px 0;
    }

    img {
      max-width: 197px;
    }
  }

  &__message {
    font-family: var(--font-stack-Akko);
    font-size: 32px;
    line-height: 1.25;
    margin: 0 0 16px;
    color: var(--gray-midnight);

    @include breakpoints.breakpoint(768) {
      margin-bottom: 24px;
    }

    &--centered {
      text-align: center;
    }
  }

  .customer-center-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    gap: 8px;

    @include breakpoints.breakpoint(768) {
      margin-bottom: 24px;
    }

    &__phone-number {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: var(--Base-Unit-S, 8px);
      border-radius: 10px;
      background: var(--ultralight-gray);
      width: 100%;
      box-sizing: border-box;

      &:before {
        content: "";
        background-image: url("../img/icon-phone.svg");
        width: 24px;
        height: 24px;
      }
    }

    &__email {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: var(--Base-Unit-S, 8px);
      border-radius: 10px;
      background: var(--ultralight-gray);
      width: 100%;
      box-sizing: border-box;

      &:before {
        content: "";
        background-image: url("../img/icon-email.svg");
        width: 24px;
        height: 24px;
      }
    }

    &__opening-hours {
      text-align: center;
      font-family: var(--font-stack-UnitOT-regular);
    }
  }

  .technical-details {
    border: 1px solid var(--gray-midnight);
    padding: 16px;
    text-align: center;
    margin-top: 16px;

    @include breakpoints.breakpoint(768) {
      margin-top: 24px;
    }

    &__headline {
      margin: 0 0 8px;
      font-size: 20px;
      font-family: var(--font-stack-UnitOT-medium);
      line-height: 1.4;
    }
    &__text {
      margin: 0;
      font-family: var(--font-stack-UnitOT-regular);
      line-height: 1.5;
    }
  }

  &__description {
    font-family: var(--font-stack-UnitOT-regular);
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700;
    color: var(--dark-gray);
    &--centered {
      text-align: center;
    }
  }
}

.register-back-to-login-error {
  color: var(--penny-red);
}
