@use "breakpoints";

.explanation {
  white-space: pre-line;
}

.content__card {
  background-color: var(--white);
  box-sizing: border-box;
  max-width: 480px;

  margin-left: auto;
  margin-right: auto;

  .card__text {
    font-size: 16px;
    line-height: 1.5;
    color: var(--dark-gray);
  }

  .card__hint {
    margin: 8px 0 16px;
    font-family: var(--font-stack-Open-Sans);
    font-size: 15px;
    line-height: 21px;
    color: var(--gray-slate);
    font-weight: 600;
  }

  .card__title {
    font-family: var(--font-stack-Akko);
    color: var(--gray-midnight);
    margin: 48px 0 16px 0;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    @include breakpoints.breakpoint(768) {
      font-size: 32px !important;
    }
  }

  .card__title.below__error {
    font-family: var(--font-stack-Akko);
    margin: 32px 0 16px 0;
  }

  .card__footer {
    margin-top: 24px;

    .button:last-child {
      margin-bottom: 0;
    }
  }
}
