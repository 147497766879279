:root {
  --font-stack-UnitOT-regular: "UnitRoundedOT", "Helvetica Neue", helvetica,
    arial, sans-serif;
  --font-stack-UnitOT-medium: "UnitRoundedOT-Medi", "Helvetica Neue", helvetica,
    arial, sans-serif;
  --font-stack-Open-Sans: "OpenSans", "Helvetica Neue", helvetica, arial,
    sans-serif;
  --font-stack-Akko: ellipsis, "PennyAkko", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

@font-face {
  font-family: "ellipsis";
  src: local("Helvetica Neue"), local(helvetica), local(arial),
    local(sans-serif);
  unicode-range: U+2026;
}

@font-face {
  font-family: "PennyAkko";
  font-style: normal;
  font-weight: normal;
  src:
    url("AkkoForPennyW00-Regular.woff") format("woff"),
    url("AkkoForPennyW00-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PennyAkko";
  font-style: normal;
  font-weight: bold;
  src:
    url("AkkoForPennyW00-Bold.woff") format("woff"),
    url("AkkoForPennyW00-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PennyAkko";
  font-style: italic;
  font-weight: bold;
  src:
    url("AkkoForPennyW00-BoldItalic.woff") format("woff"),
    url("AkkoForPennyW00-BoldItalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PennyAkko";
  font-style: normal;
  font-weight: 900;
  src:
    url("AkkoForPennyW00-Black.woff") format("woff"),
    url("AkkoForPennyW00-Black.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PennyAkko";
  font-style: normal;
  font-weight: 500;
  src:
    url("AkkoForPennyW00-Medium.woff") format("woff"),
    url("AkkoForPennyW00-Medium.ttf") format("truetype");
  font-display: swap;
}

/* OpenSans */
@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  src:
    url("OpenSans-Regular.woff") format("woff"),
    url("OpenSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: bold;
  src:
    url("OpenSans-Bold.woff") format("woff"),
    url("OpenSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  font-style: italic;
  font-weight: bold;
  src:
    url("OpenSans-BoldItalic.woff") format("woff"),
    url("OpenSans-BoldItalic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 900;
  src:
    url("OpenSans-ExtraBold.woff") format("woff"),
    url("OpenSans-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 600;
  src:
    url("OpenSans-Semibold.woff") format("woff"),
    url("OpenSans-Semibold.ttf") format("truetype");
  font-display: swap;
}
