/*
Examples:

<input class="checkbox-switch" type="checkbox"/>

<label class="label-with-checkbox-switch">
    Newsletter abonieren
    <input class="checkbox-switch" type="checkbox"/>
</label>
 */
.checkbox-switch {
  appearance: none;
  position: relative;
  display: inline-block;
  background: var(--penny-red);
  height: 32px;
  width: 58px;
  vertical-align: middle;
  border: 1px solid var(--penny-red);
  border-radius: 16px;
  transition:
    0.25s linear background,
    0.25s linear border-color;
  flex-shrink: 0;

  &::before {
    content: "Aus";
    color: var(--white);
    display: block;
    position: absolute;
    top: 3px;
    right: 2px;
    font-family: var(--font-stack-Akko);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.76px;
    transition: 0.25s linear transform;
  }

  &::after {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    background: var(--gray-sand);
    border-radius: 26px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.25s linear transform;
    transform: translateX(0);
  }

  &:checked {
    background: var(--green-478);
    border-color: var(--green-478);

    &::before {
      content: "An";
      right: 0;
      color: var(--white);
      transform: translateX(-32px);
    }

    &::after {
      transform: translateX(26px);
    }
  }
}

.label-with-checkbox-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--gray-midnight);
  font-family: var(--font-stack-open-sans);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
}
