@use "breakpoints";

.footer {
  background-color: var(--gray-midnight);
  margin-top: 48px;
  flex-shrink: 0;
  @include breakpoints.breakpoint(480) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer__faq-links {
    margin: 0;
    padding: 24px 16px;
    list-style: none;

    .footer__faq-item {
      margin-bottom: 16px;
      height: 21px;
      display: flex;
      justify-content: center;

      &:last-child {
        margin: 0;
      }
    }

    .footer__faq-link {
      text-align: center;
      font-family: var(--font-stack-Akko);
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: var(--white);
      text-decoration: none;
    }
  }

  .footer__navigation {
    background-color: var(--penny-red);
    padding: 16px;
    width: 100%;
    box-sizing: border-box;

    .footer__logo {
      height: 17px;
      display: block;
      margin: auto;
      width: 100%;
    }

    .footer__links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .footer__item {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }

      .footer__link {
        font-size: 16px;
        font-family: UnitRoundedOT, serif;
        font-weight: normal;
        line-height: 2;
        text-decoration: none;
        color: var(--dark-gray);

        &:hover {
          color: var(--penny-red);
        }
      }
    }
  }
}
