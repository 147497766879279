.message {
  padding: 16px 16px 16px 48px;
  border-radius: 16px;
  font-family: var(--font-stack-Open-Sans);
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  font-weight: 700;

  &:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: -32px;
  }

  &.info {
    background-color: var(--mediterranean-100);
    color: var(--mediterranean);

    &:before {
      background-image: url("../img/icon-info.svg");
    }
  }

  &.error {
    background-color: var(--red-100);
    color: var(--penny-red);

    &:before {
      background-image: url("../img/attention.svg");
    }
  }
}
