@use "breakpoints";

.header {
  width: 100%;

  &__link {
    width: 100%;
  }

  &__title {
    height: 48px;
    margin: 0;
    background-color: var(--penny-red);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 15px 15px;
  }

  &__logo {
    width: 89px;
    height: 17px;
  }
}

.account-content {
  & .account-heading {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--gray-midnight);
    font-family: var(--font-stack-Akko);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    & > .penny-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;

      &.penny-app-logo {
        background: var(--penny-red);
        border-radius: 4px;
      }
    }
  }
}

.account-header {
  height: 72px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-slate);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--white);

  & .account-heading {
    color: var(--gray-stone);
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.16px;
    text-transform: uppercase;

    & .icon {
      height: 24px;
      width: 24px;
    }
  }

  & .button {
    position: absolute;
    left: 16px;
  }

  & .button-back::before {
    mask-image: url("../img/icon-chevron.svg");
    -webkit-mask-image: url("../img/icon-chevron.svg");
    transform: rotate(180deg);
  }
}
