html {
  height: 100%;
}

body {
  min-height: 100%;
  color: var(--dark-gray);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
}

.content {
  padding: 0 16px;
  flex: 1 0 auto;

  &.no-header {
    padding-top: 24px;
  }
}
