@use "shop-colors";
@use "fonts";

.button {
  width: 100%;
  border: 0;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  font-family: var(--font-stack-Akko);
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 24px;
  cursor: pointer;
  height: 48px;
  line-height: 23.76px;
  margin: 16px 0;
  padding: 0 40px;

  &:disabled {
    opacity: 20%;
  }

  &.icon::before {
    content: "";
    width: 24px;
    height: 24px;
    mask-size: 100%;
    --webkit-mask-size: 100%;
    mask-repeat: no-repeat;
    --webkit-mask-repeat: no-repeat;
    mask-position: center;
    --webkit-mask-position: center;
  }

  &-primary {
    background-color: var(--penny-red);
    color: var(--white);

    box-shadow:
      0px 5px 5px -3px rgba(205, 20, 20, 0.2),
      0px 3px 14px 2px rgba(205, 20, 20, 0.12),
      0px 8px 10px 1px rgba(205, 20, 20, 0.14);

    &.icon::before {
      background: var(--white);
    }

    &:hover {
      background-color: var(--penny-red-hover);
      box-shadow:
        0 2px 1px 0 #cd141433,
        0 1px 1px 0 #4106064d;
    }

    &:disabled {
      color: var(--white);
      background-color: var(--penny-red);
    }

    &:active {
      box-shadow: none;
    }
  }

  &-secondary {
    color: var(--penny-red);
    border: 1px solid var(--penny-red);
    background: var(--white);

    &.icon::before {
      background: var(--penny-red);
    }

    &:hover:enabled {
      color: var(--white);
      background-color: var(--penny-red);

      &.icon::before {
        background: var(--white);
      }
    }

    .button__spinner .spinner__bubble:before {
      background-color: var(--dark-gray) !important;
    }
  }

  &-tertiary {
    border: 1px solid var(--gray-midnight);
    background-color: var(--white);
    color: var(--gray-midnight);

    &.icon::before {
      background: var(--gray-midnight);
    }

    &:hover {
      color: var(--white);
      background-color: var(--gray-midnight);

      &.icon::before {
        background: var(--white);
      }
    }

    .button__spinner .spinner__bubble:before {
      background-color: var(--dark-gray) !important;
    }
  }

  &-size-m {
    height: 34px;
    padding: 0 16px;
    width: fit-content;

    &.button-circle {
      width: 34px;
      border-radius: 32px;
      padding: 0;
    }

    &.icon::before {
      width: 20px;
      height: 20px;
    }
  }

  &-link {
    font-family: var(--font-stack-Open-Sans);
    font-weight: 600;
    background-color: var(--white);
    border: 0;
    color: var(--penny-red);
    text-decoration: underline;
    height: auto;
    line-height: 21px;
  }

  &--clicked {
    pointer-events: none;
    background-color: var(--gray-wild-sand);
    box-shadow: none;
  }

  .button__spinner {
    background-image: url("../img/spinner.svg");
    position: relative;
    height: 32px;
    width: 32px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    .spinner__bubble:before {
      background-color: var(--white);
    }
    animation: spin 2s linear infinite;
  }
}
