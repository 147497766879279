.alert {
  padding: 16px 8px 16px 48px;
  margin: 16px 0;
  border-radius: 16px;
  font-family: var(--font-stack-Open-Sans);
  font-size: 15px;
  line-height: 21px;
  text-align: left;
  font-weight: 700;

  & ul {
    margin: 0;
    padding-left: 2em;
  }

  &__success,
  &__notification {
    color: var(--notification);
    background-color: var(--blue-100);
  }

  &__warning {
    color: var(--alert);
    background-color: var(--orange-100);
  }

  &__info {
    color: var(--mediterranean);
    background-color: var(--mediterranean-100);

    & a {
      color: var(--mediterranean);
    }

    &:before {
      content: "";
      background-image: url("../img/icon-info.svg");
      position: absolute;
      width: 24px;
      height: 24px;
      margin-left: -32px;
    }
  }

  &__error {
    color: var(--penny-red);
    background-color: var(--red-100);

    & a {
      color: var(--penny-red);
    }

    &:before {
      content: "";
      background-image: url("../img/attention.svg");
      position: absolute;
      width: 24px;
      height: 24px;
      margin-left: -32px;
    }
  }
}

.no_alert {
  // Empty placeholder to add missing height when no alert is present
  margin: 0;
  height: 16px;
}
